var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { title: _vm.$t("lbl_sales_order") } },
    [
      _c(
        "a-row",
        { attrs: { gutter: [16, 16] } },
        [
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [
              _c(
                "a-form-model",
                {
                  ref: "formModel",
                  attrs: {
                    model: _vm.filters,
                    layout: "horizontal",
                    "label-col": { span: 8 },
                    "wrapper-col": { span: 14 },
                    "label-align": "left",
                    "data-testid": "trucking-sales-order-filter-form"
                  }
                },
                [
                  _c(
                    "a-row",
                    { attrs: { gutter: 16 } },
                    [
                      _c(
                        "a-col",
                        { attrs: { span: 12, xs: 24, sm: 24, md: 24, lg: 12 } },
                        [
                          _c(
                            "a-form-model-item",
                            {
                              attrs: {
                                label: _vm.$t("lbl_sales_order_number"),
                                prop: "salesOrderNumber"
                              }
                            },
                            [
                              _c("a-select", {
                                attrs: {
                                  "label-in-value": "",
                                  placeholder: _vm.$t("common.search-by-text", {
                                    text: _vm.$t("lbl_sales_order_number")
                                  }),
                                  "show-search": "",
                                  "allow-clear": "",
                                  loading: _vm.loading.salesOrder,
                                  "filter-option": false,
                                  options: _vm.dataListSalesOrder,
                                  "data-testid":
                                    "trucking-sales-order-filter-order-number"
                                },
                                on: { search: _vm.getListSalesOrderHeaderOnly },
                                model: {
                                  value: _vm.filters.salesOrderNumber,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.filters,
                                      "salesOrderNumber",
                                      $$v
                                    )
                                  },
                                  expression: "filters.salesOrderNumber"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "a-form-model-item",
                            {
                              attrs: { label: _vm.$t("lbl_date"), prop: "date" }
                            },
                            [
                              _c("a-range-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  "data-testid":
                                    "trucking-sales-order-filter-date",
                                  format: _vm.DEFAULT_DATE_FORMAT,
                                  placeholder: [
                                    _vm.$t("lbl_start_date"),
                                    _vm.$t("lbl_end_date")
                                  ]
                                },
                                model: {
                                  value: _vm.filters.date,
                                  callback: function($$v) {
                                    _vm.$set(_vm.filters, "date", $$v)
                                  },
                                  expression: "filters.date"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "a-form-model-item",
                            {
                              attrs: {
                                label: _vm.$t("lbl_pi_number"),
                                prop: "piNumber"
                              }
                            },
                            [
                              _c("a-input", {
                                attrs: {
                                  placeholder: _vm.$t("common.search-by-text", {
                                    text: _vm.$t("lbl_pi_number")
                                  }),
                                  "allow-clear": "",
                                  "data-testid":
                                    "trucking-sales-order-filter-pi-number"
                                },
                                model: {
                                  value: _vm.filters.piNumber,
                                  callback: function($$v) {
                                    _vm.$set(_vm.filters, "piNumber", $$v)
                                  },
                                  expression: "filters.piNumber"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { span: 12, xs: 24, sm: 24, md: 24, lg: 12 } },
                        [
                          _c(
                            "a-form-model-item",
                            {
                              attrs: {
                                label: _vm.$t("lbl_customer"),
                                prop: "customer"
                              }
                            },
                            [
                              _c("a-select", {
                                attrs: {
                                  "label-in-value": "",
                                  placeholder: _vm.$t("common.search-by-text", {
                                    text: _vm.$t("lbl_customer")
                                  }),
                                  "show-search": "",
                                  "allow-clear": "",
                                  loading: _vm.loading.customer,
                                  "filter-option": false,
                                  options: _vm.dataListCustomer,
                                  "data-testid":
                                    "trucking-sales-order-filter-customer"
                                },
                                on: { search: _vm.getCustomer },
                                model: {
                                  value: _vm.filters.customer,
                                  callback: function($$v) {
                                    _vm.$set(_vm.filters, "customer", $$v)
                                  },
                                  expression: "filters.customer"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "a-form-model-item",
                            {
                              attrs: {
                                label: _vm.$t("lbl_status"),
                                prop: "status"
                              }
                            },
                            [
                              _c("a-select", {
                                attrs: {
                                  "label-in-value": "",
                                  placeholder: _vm.$t("common.search-by-text", {
                                    text: _vm.$t("lbl_status")
                                  }),
                                  "allow-clear": "",
                                  loading: _vm.loading.status,
                                  "filter-option": false,
                                  options: _vm.dataListStatus,
                                  "data-testid":
                                    "trucking-sales-order-filter-status"
                                },
                                model: {
                                  value: _vm.filters.status,
                                  callback: function($$v) {
                                    _vm.$set(_vm.filters, "status", $$v)
                                  },
                                  expression: "filters.status"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 12 } },
            [
              _c(
                "a-space",
                [
                  _c(
                    "a-button",
                    {
                      attrs: { "data-testid": "trucking-sales-order-reset" },
                      on: { click: _vm.handleReset }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_reset")) + " ")]
                  ),
                  _c(
                    "a-button",
                    {
                      attrs: {
                        type: "primary",
                        "data-testid": "trucking-sales-order-find",
                        loading: _vm.loading.find
                      },
                      on: { click: _vm.handleFind }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_find")) + " ")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 12, align: "end" } },
            [
              _c(
                "router-link",
                { attrs: { to: { name: "trucking.sales-order.create" } } },
                [
                  _c(
                    "a-button",
                    {
                      attrs: {
                        type: "primary",
                        "data-testid": "trucking-sales-order-create"
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_create_new")) + " ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-row",
        { attrs: { gutter: [16, 16] } },
        [
          _c(
            "a-col",
            [
              _c("a-table", {
                attrs: {
                  size: "small",
                  dataSource: _vm.salesOrders.data,
                  columns: _vm.salesOrderTableColumns,
                  "row-key": "id",
                  loading: _vm.loading.find,
                  pagination: {
                    showTotal: function() {
                      return _vm.$t("lbl_total_items", {
                        total: _vm.salesOrders.totalElements
                      })
                    },
                    total: _vm.salesOrders.totalElements,
                    showSizeChanger: true,
                    pageSizeOptions: _vm.PAGE_SIZE_OPTIONS,
                    current: _vm.pagination.page,
                    defaultPageSize: _vm.pagination.limit
                  },
                  "row-class-name": function(_record, index) {
                    return (
                      (index % 2 ? "bg-white" : "bg-gray-light") + " pointer"
                    )
                  },
                  scroll: { y: 800 },
                  "data-testid": "trucking-sales-order-table",
                  "expand-row-by-click": ""
                },
                on: { change: _vm.onChangeTable },
                scopedSlots: _vm._u([
                  {
                    key: "action",
                    fn: function(text, record) {
                      return [
                        _c(
                          "a",
                          {
                            staticClass: "text-link",
                            on: {
                              click: function($event) {
                                return _vm.handleView(record)
                              }
                            }
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("lbl_view")) + " ")]
                        )
                      ]
                    }
                  },
                  {
                    key: "expandedRowRender",
                    fn: function(record) {
                      return [
                        _c("a-table", {
                          attrs: {
                            size: "small",
                            dataSource: record.salesOrderLines,
                            columns: _vm.nestedTableColumns,
                            "row-key": "id",
                            "row-class-name": function(_record, index) {
                              return index % 2 ? "bg-white" : "bg-gray-light"
                            },
                            pagination: {
                              showTotal: function() {
                                return _vm.$t("lbl_total_items", {
                                  total: _vm.salesOrders.totalElements
                                })
                              }
                            },
                            scroll: { x: "calc(300px + 50%)", y: 500 }
                          }
                        })
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }